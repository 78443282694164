import { toast } from 'react-toastify';
import * as yup from 'yup';
import { transformAsCurrency } from '~/utils/functions';

export const schema = yup
  .object({
    cod_loja: yup.number().required(),
    cod_pessoa: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    cod_perfil: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    num_serie: yup.mixed().when('cod_perfil', {
      is: (value: any) => {
        if (value !== null) {
          return value?.tipo_emissao === 0;
        }
        return true;
      },
      then: yup.string().required(),
      otherwise: yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
        .required(),
    }),
    // num_serie: yup.object().when('cod_perfil', {
    //   is: (value: any) => {
    //     if (value?.tipo_operacao !== 1) return true;
    //     return false;
    //   },
    //   then: yup.object().shape({
    //     label: yup.string().required(),
    //     value: yup.number().required(),
    //   }),
    //   otherwise: yup.object().notRequired().nullable(true),
    // }),
    num_especie: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
  })
  .required();

export const schemaModal = yup
  .object({
    produto: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    trib: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    ncm: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    cfop: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    val_unitario: yup
      .string()
      .test((value: any) => {
        if (transformAsCurrency(value || 0) > 0) {
          return true;
        }
        return false;
      })
      .required(),
    qtd_embalagem: yup
      .string()
      .test((value: any) => {
        if (transformAsCurrency(value || 0) > 0) {
          return true;
        }
        return false;
      })
      .required(),
    qtd_entrada: yup
      .string()
      .test((value: any) => {
        if (transformAsCurrency(value || 0) > 0) {
          return true;
        }
        return false;
      })
      .required(),
    val_desconto: yup
      .string()
      .test((value: any) => {
        if (transformAsCurrency(value || 0) >= 0) {
          return true;
        }
        return false;
      })
      .required(),
    val_despesa_acessoria: yup.string().required(),
    val_ipi: yup
      .string()
      .test((value: any) => {
        if (transformAsCurrency(value || 0) >= 0) {
          return true;
        }
        return false;
      })
      .required(),
    val_bc_st: yup
      .string()
      .test((value: any) => {
        if (transformAsCurrency(value || 0) >= 0) {
          return true;
        }
        return false;
      })
      .required(),
    val_icms_st: yup
      .string()
      .test((value: any) => {
        if (transformAsCurrency(value || 0) >= 0) {
          return true;
        }
        return false;
      })
      .required(),
    val_frete: yup
      .string()
      .test((value: any) => {
        if (transformAsCurrency(value || 0) >= 0) {
          return true;
        }
        return false;
      })
      .required(),
    pis_cofins: yup
      .string()
      .test((value: any) => {
        if (transformAsCurrency(value || 0) >= 0) {
          return true;
        }
        return false;
      })
      .required(),
  })
  .required();

export const schemaModalAjuste = yup
  .object({
    tipo_ajuste: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    dta_ini_ajuste: yup.string().required(),
    dta_fim_ajuste: yup.string().required(),
  })
  .required();

export const schemaTransporte = yup
  .object({
    val_seguro: yup.string().required(),
    qtd_embalagem: yup.number().required(),
    qtd_peso_liq: yup.string().required(),
    qtd_peso_bruto: yup.string().required(),
    des_marca_veiculo: yup.string().required(),
    des_placa_veiculo: yup.string().required(),
    des_obs_fiscal: yup.string().required(),
  })
  .required();

export const schemaFinanceiro = yup
  .object({
    cod_finalizadora: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    num_condicao: yup.number().required(),
    condicao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    dta_vencimento: yup.string().required(),
    val_financeiro: yup.string().required(),
  })
  .required();

export const schemaLog = yup.object({
  cod_xml: yup.string(),
});
