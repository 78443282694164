import api from '~/services/api';
import { ItensFinaceiroProps } from '../protocols/ItensFinaceiroProps';
import { ParcelasFinanceiroProps } from '../protocols/ParcelasFinanceiroProps';

export default async function getParcelasFinanceiro(cod_seq_nf: number) {
  const res = await api.get(
    `/nf-outras-operacoes/parcelas-financeiro/${cod_seq_nf}`,
  );
  const { data, success, message } = res.data;
  if (!success) {
    throw new Error(message);
  }

  const regs: ItensFinaceiroProps[] = data.map(
    (item: ParcelasFinanceiroProps, index: number) => {
      return {
        num_registro: index,
        isDisabled: true,
        ...item,
      };
    },
  );

  return regs;
}
